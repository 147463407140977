<template>
    <div
        class="vh-100"
        style="display: flex; flex-direction: column; justify-content: center; align-items: center"
    >
        <img
            src="@/assets/images/origination/VideoCamera.svg"
            alt="Video Camera Permissions"
        >
        <h5
            class="fw-light mt-3"
            v-html="permissionsText"
        />
    </div>
</template>

<script lang="js">
    import {getNextRoute} from "@/flow/flowController";
    import {i18n} from "@/utils/i18n";
    import {logger} from "@/utils/logger";
    import {sharedPagePaths} from "@/routes/sharedRoutes";
    import { isAndroid, isAndroidBrowserOrChrome, isIOS, isIOSChromeFF, isIOSSafari } from '@/utils/parseUserAgents'
    import { clonePrototypeAsObject } from '@/utils/serialization'
    import { inspect } from '@/utils/inspect'

    export default {
        data: function () {
            return {
                permissionsText: i18n.t('pages.origination.notaryPermissions.title'),
                availableVideoDevices: null,
                availableAudioDevices: null
            }
        },
        mounted: async function() {
            const isRedirecting = await this.redirectIfUnsupportedBrowser()
            if (isRedirecting) {
                logger.log('Redirecting to unsupported browser page...')
                return // If we get a value here, it means the above function is redirecting
            }

            if (!await this.tryGetDevicesExist()) {
                logger.log('Missing either a webcam device, microphone, or both. Redirecting...')
                return await this.$router.push({
                    path: sharedPagePaths.NOTARY_REQUIRES_AV,
                });
            }

            this.$logEvent('view_notary_av_permissions')
            setTimeout(this.tryGetAVPermissions, 1000)
        },
        methods: {
            redirectIfUnsupportedBrowser: async function () {
                logger.log('Checking browser / OS type... ' + window.navigator.userAgent)

                if (isIOS()) {
                    logger.info('User is on an iOS device')

                    if (isIOSChromeFF() || !isIOSSafari()) {
                        logger.info('Need to redirect to user from criOS / other to safari')
                        await this.$router.push({ path: sharedPagePaths.NOTARY_REQUIRES_SAFARI })
                        return true
                    } else {
                        logger.info('User is on regular iOS Safari')
                    }
                } else {
                    logger.info('User is not on iOS')
                }

                if (isAndroid()) {
                    logger.info('User is on an android device')
                    if (!isAndroidBrowserOrChrome()) {
                        logger.info('User is using a non-stock android browser, redirecting')
                        await this.$router.push({ path: sharedPagePaths.NOTARY_REQUIRES_CHROME })
                        return true
                    } else {
                        logger.info('User is on regular stock android browser / chrome')
                    }
                } else {
                    logger.info('User is not on Android')
                }

                return false
            },
            tryGetDevicesExist: async function () {
                try {
                    console.log("Attempting to detect if AV devices exist")
                    // Uncomment this if you want to test 'no devices available' functionality
                    // throw new Error('No devices available')
                    const devices = await navigator.mediaDevices.enumerateDevices()

                    this.availableVideoDevices = devices.filter((device) => device.kind === 'videoinput')
                        // Blacklist krisp video devices (expand as necessary)
                        .filter((device) => !/krisp/gi.test(device.label))
                    logger.log(`Got ${this.availableVideoDevices.length} video devices: ${JSON.stringify(this.availableVideoDevices)}`)

                    this.availableAudioDevices = devices.filter((device) => device.kind === 'audioinput')
                    logger.log(`Got ${this.availableAudioDevices.length} audio devices: ${JSON.stringify(this.availableAudioDevices)}`)

                    return (this.availableVideoDevices.length > 0 && this.availableAudioDevices.length > 0)
                } catch (e) {
                    logger.error(`Enumeration failed, assuming no video / audio devices available`, null /* event */, e)
                    return false
                }
            },
            tryGetAVPermissions: async function () {
                try {
                    const desiredDevices = {
                        audio: {
                            deviceId: this.availableAudioDevices?.[0]?.deviceId // Idx '0' is the default device
                        },
                        video: {
                            deviceId: this.availableVideoDevices?.[0]?.deviceId // Idx '0' is the default device
                        }
                    }

                    logger.log(`Attempting to capture AV permissions for ${JSON.stringify(desiredDevices)}`)
                    // This is where we expect to throw an exception on AV permissions reject
                    const media = await navigator.mediaDevices.getUserMedia(desiredDevices)

                    this.$logEvent('event_notary_av_permissions_accepted')

                    logger.log('Stopping media streams...')
                    // Stop the streams for the acquired media
                    media.getTracks().forEach( (track) => {
                        track.stop();
                    });

                    this.tryGetLocationPermissions()
                } catch (e) {
                    logger.log(`AV permissions capture failed: ${e}`)
                    this.$logEvent('event_notary_av_permissions_rejected')
                    this.permissionsText = i18n.t('pages.origination.notaryPermissions.noPermissions')
                }
            },
            tryGetLocationPermissions: async function () {
                try {
                    this.$logEvent('view_notary_location_permissions')
                    logger.log("Attempting to capture location permissions")
                    this.permissionsText = i18n.t('pages.origination.notaryPermissions.location')

                    // typeof location === GeolocationPosition
                    // getCurrentPosition returns a prototype object that can't be easily serialized without clonePrototypeAsObject
                    const location = await new Promise((resolve, reject) => {
                        if (!navigator?.geolocation) { reject("Missing navigator.geolocation") }
                        navigator.geolocation.getCurrentPosition(res => resolve(clonePrototypeAsObject(res)), e => reject(clonePrototypeAsObject(e)), {
                            timeout: 15 * 1000,
                            enableHighAccuracy: true
                        });
                    })

                    logger.log(`Got location! ${inspect(location)}`)
                    this.$logEvent('event_notary_av_permissions_accepted', location)
                } catch (e) {
                    // typeof e === GeolocationPermissionError
                    logger.log(`Location permissions failed: ${inspect(e)}`)
                    this.$logEvent('event_notary_location_permissions_rejected')
                } finally {
                    this.permissionsText = i18n.t('pages.origination.notaryPermissions.thanks')
                    setTimeout(() => this.$router.push(getNextRoute(this.$router)), 1000)
                }
            }
        }
    };
</script>
