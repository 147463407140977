// This is necessary to flatten some prototype-based objects into a POJO
// For example, GeolocationPosition
// https://stackoverflow.com/a/37726654/858775
export function clonePrototypeAsObject(obj) {
    if (obj === null || !(obj instanceof Object)) {
        return obj
    }
    const temp = obj instanceof Array ? [] : {}
    for (const key in obj) {
        temp[key] = clonePrototypeAsObject(obj[key])
    }
    return temp
}
